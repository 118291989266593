import './App.css';
import {RouterProvider} from "react-router-dom";
import {userRoutes} from "./routes";
import React, {useEffect, useState} from "react";

import {useUserStore} from "./constans/StoreUserData";
import {get} from "sodon-http/API";
import {API_URL} from "./constans/API_URL";
import {Button, ThemeProvider} from "@mui/material";
import {createTheme} from "@mui/material/styles"


function App() {

    const { user, setUser } = useUserStore();
    const [signedUser, setSignedUser] = useState({});

    async function checkUserServerSession() {
        try {
            const response = await get(`${API_URL}/api/core/signedUser`);
            if (response.success) {
                // console.log("SIGNED USER", response.payload);
                // console.log("User has Stored")
                await setSignedUser(response.payload)
            } else {
                // console.log("SIGNED OUT USER", response.payload);
                await setSignedUser(null);
            }
        } catch (e) {

        }
    }

    let theme = createTheme({
        palette: {
            primary: {
                main: "#242424",
                dark: "#242424"
            },
            secondary: {
                main: "#2e2e2e",
                dark: "#242424"
            },
            mode: "dark"
        }
    })

    function checkingUser() {
        // console.log("Here is your user", user);
        // checkUser();
    }

    const darkTheme = createTheme(theme, {
        palette: {
            info: {
                main: theme.palette.primary.main
            }
        }
    })

    useEffect(() => {
        /*console.clear();
        console.log("USERZUSTAND:", user);
        console.log("signedUser:", signedUser);
        if (!user || JSON.stringify(user) === "{}" && signedUser) {
            console.log("Signed user found", signedUser);
            setUser(signedUser);
        } else if (!signedUser) {
            console.log("Couldn't find user", signedUser);
            setUser(null);
        }*/
    }, [user, signedUser]);

    useEffect(() => {
        checkUserServerSession();
    }, []);



    return (
        <>
            <ThemeProvider theme={darkTheme}>
                <div style={{display: "flex", flexFlow: "column", minHeight: "100vh", backgroundColor: "#242424"}}>
                    <RouterProvider router={userRoutes}/>
                </div>
            </ThemeProvider>
        </>
    );
}

export default App;
